import React from 'react'
import './Drawer.scss'

const Drawer = (props: any) => {
  return (
    <>
      <div className="nav-menu-wrapper fade-in" role="button" onClick={props.onClose} />
      <div className={`menu-sidebar slide-in-right ${props.noPaddingX ? 'no-padding-x' : ''}`} {...props}>
        <i className="la la-close close-btn la-2x" onClick={props.onClose} />
        {props.children}
      </div>
    </>
  )
}

export default Drawer
