import React from 'react'

import Image from '../../assets/images/MessyDoodle.png'

const SubmitApplication = ({ label, mode, name }: any): JSX.Element => {
  return (
    <div className="submitted-application">
      <img src={Image} alt="splash" className="fade-in-bottom dl-2" />
      <h1 className="fade-in-bottom dl-4">
        Thanks, <span>{name}!</span>
      </h1>
      <p className="fade-in-bottom dl-6">
        {mode !== 'scheduled-visit' && (
          <>
            Your application for <strong>{label || 'this job opening'}</strong> has been submitted, we'll get back to you as soon as we
            review your application.
          </>
        )}

        {mode === 'scheduled-visit' && <>Your request for a visit has been submitted, we'll get back to you as soon as possible.</>}
      </p>
    </div>
  )
}

export default SubmitApplication
