import axios from 'axios'
import { graphql, useStaticQuery } from 'gatsby'
import React, { ReactElement, useEffect, useState } from 'react'
import FloatingLabelInput from 'react-floating-label-input'
import CopyToClipboard from 'react-copy-to-clipboard'
import { FacebookShareButton, EmailShareButton, TwitterShareButton } from 'react-share'
import ReCAPTCHA from 'react-google-recaptcha'
import moment from 'moment'

import './CareerDetailsPage.scss'
import Drawer from '../../components/Drawer/Drawer'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import SEO from '../../components/SEO/SEO'
import SubmitApplication from './SubmittedApplication'
import { ellipsis, isContact, isEmail } from '../../components/Helpers/helpers'
import { alphaOnly, numericOnly, preventInput, removeTextTag, clearTextTag } from '../../components/Common/Validator'

const ALISI_BRAND_ID: any = process.env.GATSBY_API_ALISI_BRAND_ID
const API_URL: any = process.env.GATSBY_API_URL

const CareerDetailsPage = ({ pageContext: { data } }: any): ReactElement => {
  const careersDetailsHeroBanner = useStaticQuery(graphql`
    query {
      heroBanner(title: { eq: "Careers Details" }) {
        image
      }
    }
  `)

  const [apply, setApply] = useState<boolean>(false)
  const [copied, setCopied] = useState<boolean>(false)
  const [url, setURL] = useState<string>('')
  const [terms, setTerms] = useState<boolean>(false)
  const [submitting, setSubmit] = useState<boolean>(false)
  const [errors, setErrors] = useState<any>({})
  const [submitted, setSubmitted] = useState<boolean>(false)

  const [form, setForm] = useState<any>({
    first_name: '',
    last_name: '',
    middle_name: '',
    email: '',
    mobile_number: '',
    resume: {}
  })

  const [captcha, setCaptcha] = useState<boolean>(false)

  const toggleApply = () => {
    setApply(!apply)
  }

  const startCVUpload = () => {
    let fileInput = document.querySelector('#application-cv') as HTMLElement
    fileInput.click()
  }

  const copyLink = () => {
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 1200)
  }

  const toggleTerms = () => {
    setTerms(!terms)
  }

  const getFile = () => {
    let file: any = document.getElementById('application-cv')
    let formState: any = { ...form }
    formState.file = file.files[0]
    setForm(formState)
  }

  const submitApplication = () => {
    const formError: any = {}

    if (form.first_name.trim() === '') {
      formError.firstName = 'This field is required!'
    }

    if (form.last_name.trim() === '') {
      formError.lastName = 'This field is required!'
    }

    if (!isEmail(form.email)) {
      formError.email = 'Please provide a proper email address.'
    }

    if (!isContact(form.mobile_number)) {
      formError.mobileNumber = 'Please provide a proper contact number.'
    }

    let filesize = form.file ? form.file.size / 1024 / 1024 : 0

    if (!form.file) {
      formError.file = 'You need to attach a CV file. It must be in Doc/Docx or PDF format, 5MB maximum file size.'
    } else {
      let allowed = ['pdf', 'doc', 'docx']
      let ftype = form.file.name.split('.').pop().toLowerCase()
      if (allowed.includes(ftype)) {
        if (filesize > 5) {
          formError.file = 'Attachment is too large, 5MB maximum file size.'
        }
      } else {
        formError.file = 'Wrong file format! It must be in Doc/Docx or PDF format, 5MB maximum file size.'
      }
    }

    if (!terms || !captcha) {
      formError.general = 'You must Agree to the Terms and Conditions, Privacy Notice and Complete the reCAPTCHA to continue.'
    }

    if (Object.keys(formError).length === 0) {
      setSubmit(true)
      setErrors(formError)
      let file: any = document.getElementById('application-cv')

      // submitform

      const formData = new FormData()
      formData.append('brandId', ALISI_BRAND_ID),
        formData.append('firstName', form.first_name),
        formData.append('middleName', form.middle_name),
        formData.append('lastName', form.last_name),
        formData.append('email', form.email),
        formData.append('contactNumber', form.mobile_number),
        formData.append('position', data.title),
        formData.append('resume', file.files[0])

      axios
        .post(`${API_URL}/careers`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res: any) => {
          setSubmitted(true)
        })
        .catch((err: any) => {
          console.error(err)
          setSubmit(false)
          setErrors({
            general: 'Submission failed, please try again later.'
          })
        })
    } else {
      setSubmit(false)
      setErrors(formError)
    }
  }

  useEffect(() => {
    setURL(window.location.href)
  }, [])

  return (
    <>
      <SEO title={`Careers | ${data.title} | AyalaLand International Sales Inc.`} />
      <Header solid={true} />
      <div className="career-details-page">
        <div
          className="career-details-banner"
          style={{
            background: `linear-gradient(rgba(85, 67, 43, 0.5), rgba(16, 16, 37, 0.575)),
            url(${careersDetailsHeroBanner.heroBanner ? careersDetailsHeroBanner.heroBanner.image : ''}) center fixed no-repeat`
          }}
        />
        <div className="details-wrapper">
          <div className="details-content">
            <h3 className="details-tag">CAREERS</h3>
            <div className="details-header">
              <div className="details-info">
                <span className="title">{data.title}</span>
                <div className="job-tags">
                  {data.tags.map((tag: any, index: any) => {
                    return <span key={index}>{tag}</span>
                  })}
                </div>
                <span className="date">
                  Posted {moment(data.created_at).fromNow()} <i className="la la-circle" /> Apply before{' '}
                  {moment(data.apply_before_at).format('MMM D')}
                </span>
              </div>

              <div className="social-icons">
                <FacebookShareButton url={url}>
                  <div className="icon fade-in">
                    <i className="la la-facebook" />
                  </div>
                </FacebookShareButton>

                <TwitterShareButton url={url}>
                  <div className="icon fade-in">
                    <i className="la la-twitter" />
                  </div>
                </TwitterShareButton>

                <EmailShareButton url={url}>
                  <div className="icon fade-in">
                    <i className="la la-envelope" />
                  </div>
                </EmailShareButton>
                {!copied ? (
                  <CopyToClipboard text={url} onCopy={copyLink}>
                    <div className="icon fade-in">
                      <i className="la la-link" />
                    </div>
                  </CopyToClipboard>
                ) : (
                  <span className="copied-link fade-in">Link copied to Clipboard!</span>
                )}
              </div>
            </div>

            <div className="job-details">
              <h3 className="detail-title">{data.job_description_label}</h3>
              <div className="content-container" dangerouslySetInnerHTML={{ __html: data.job_description }} />
            </div>

            <div className="job-details">
              <h3 className="detail-title">{data.qualifications_label}</h3>
              <div className="content-container" dangerouslySetInnerHTML={{ __html: data.qualifications }} />
            </div>

            <div className="button-container">
              <button className="apply-button" onClick={toggleApply}>
                Apply now
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      {apply && (
        <>
          <Drawer onClose={toggleApply} noPaddingX={true} style={{ width: '455px' }}>
            {!submitted ? (
              <div className="application-drawer">
                <span className="job-title">{data.title}</span>
                <small>Complete the Application form to continue.</small>

                <div className="application-form">
                  {errors.general && (
                    <small className="field-error fade-in">
                      <i className="la la-exclamation-circle" /> {errors.general}
                    </small>
                  )}
                  <div className={`floating-input  ${errors.firstName ? 'input-error' : ''}`}>
                    <FloatingLabelInput
                      id="first-name"
                      label="First Name"
                      onPaste={preventInput}
                      onChange={(e: any) => {
                        if (!e.nativeEvent.inputType) {
                          clearTextTag(e.nativeEvent.target.id)
                          return
                        }
                        const isAplha = alphaOnly(e)

                        if (!isAplha) {
                          removeTextTag(e.nativeEvent.target.id)
                          return
                        }
                        let formState: any = { ...form }
                        formState.first_name = e.target.value
                        setForm(formState)
                      }}
                    />
                  </div>

                  {errors.firstName && (
                    <small className="field-error fade-in">
                      <i className="la la-exclamation-circle" /> {errors.firstName}
                    </small>
                  )}

                  <div className={`floating-input ${errors.lastName ? 'input-error' : ''}`}>
                    <FloatingLabelInput
                      id="last-name"
                      label="Last Name"
                      onPaste={preventInput}
                      onChange={(e: any) => {
                        if (!e.nativeEvent.inputType) {
                          clearTextTag(e.nativeEvent.target.id)
                          return
                        }
                        const isAplha = alphaOnly(e)

                        if (!isAplha) {
                          removeTextTag(e.nativeEvent.target.id)
                          return
                        }
                        let formState: any = { ...form }
                        formState.last_name = e.target.value
                        setForm(formState)
                      }}
                    />
                  </div>

                  {errors.lastName && (
                    <small className="field-error fade-in">
                      <i className="la la-exclamation-circle" /> {errors.lastName}
                    </small>
                  )}

                  <div className={`floating-input`}>
                    <FloatingLabelInput
                      id="middle-name"
                      label="Middle Name"
                      onPaste={preventInput}
                      onChange={(e: any) => {
                        if (!e.nativeEvent.inputType) {
                          clearTextTag(e.nativeEvent.target.id)
                          return
                        }
                        const isAplha = alphaOnly(e)

                        if (!isAplha) {
                          removeTextTag(e.nativeEvent.target.id)
                          return
                        }
                        let formState: any = { ...form }
                        formState.middle_name = e.target.value
                        setForm(formState)
                      }}
                    />
                  </div>

                  <div className={`floating-input ${errors.email ? 'input-error' : ''}`}>
                    <FloatingLabelInput
                      id="email-address"
                      label="Email Address"
                      onChange={(e: any) => {
                        let formState: any = { ...form }
                        formState.email = e.target.value
                        setForm(formState)
                      }}
                    />
                  </div>

                  {errors.email && (
                    <small className="field-error fade-in">
                      <i className="la la-exclamation-circle" /> {errors.email}
                    </small>
                  )}

                  <div className={`floating-input ${errors.mobileNumber ? 'input-error' : ''}`}>
                    <FloatingLabelInput
                      id="contact-number"
                      label="Contact Number"
                      maxLength="12"
                      onKeyPress={numericOnly}
                      onChange={(e: any) => {
                        if (!e.nativeEvent.inputType) {
                          clearTextTag(e.nativeEvent.target.id)
                          return
                        }
                        const isAplha = numericOnly(e)

                        if (!isAplha) {
                          removeTextTag(e.nativeEvent.target.id)
                          return
                        }
                        let formState: any = { ...form }
                        formState.mobile_number = e.target.value
                        setForm(formState)
                      }}
                    />
                  </div>
                  {errors.mobileNumber && (
                    <small className="field-error fade-in">
                      <i className="la la-exclamation-circle" /> {errors.mobileNumber}
                    </small>
                  )}

                  <div className={`floating-input-file ${errors.file ? 'input-error' : ''}`}>
                    <input type="file" id="application-cv" onChange={getFile} />
                    <div className="file-input-ui">
                      <span>
                        {form.file && form.file.name ? (
                          <span className="file-name" title={form.file.name}>
                            {ellipsis(form.file.name, 20)}
                          </span>
                        ) : (
                          'CV Attachment'
                        )}
                      </span>
                      <div className="file-upload-btn" onClick={startCVUpload}>
                        Upload
                      </div>
                    </div>
                  </div>

                  {errors.file && (
                    <small className="field-error fade-in">
                      <i className="la la-exclamation-circle" /> {errors.file}
                    </small>
                  )}

                  <div className="form-agreement" onClick={toggleTerms}>
                    <div className="terms-checkbox">{terms && <i className="la la-check" />}</div>
                    <span>
                      I have read and agreed with the <a href="/privacy-notice">Privacy Notice</a> and{' '}
                      <a href="/terms-and-conditions">Terms and Conditions</a>
                    </span>
                  </div>

                  <ReCAPTCHA
                    sitekey="6LdPbLsZAAAAAKfFgRSCPS4hpp9-YeKDzrfWhsbr"
                    onChange={() => {
                      setCaptcha(true)
                    }}
                    onExpired={() => {
                      setCaptcha(false)
                    }}
                    onErrored={() => {
                      setCaptcha(false)
                    }}
                  />
                </div>
              </div>
            ) : (
              <SubmitApplication name={form.first_name} label={data.title} />
            )}
          </Drawer>
          {!submitted && (
            <button className={`job-submit-button slide-in-right ${submitting ? 'disabled' : ''}`} onClick={submitApplication}>
              <span>{submitting ? 'Submitting...' : 'Submit'}</span>
            </button>
          )}
        </>
      )}
    </>
  )
}

export default CareerDetailsPage
